import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class SenseiGrahamRavey extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Instructor profile: Sensei Graham Ravey - Australasian BLITZ c1990</h1>
                <StaticImage src="../../../images/senseigrahamravey/ResizedImage600820-5511828.jpg"
                             alt="Sensei Ravey Page 1"/>
                <StaticImage src="../../../images/senseigrahamravey/ResizedImage600821-5517092.jpg"
                             alt="Sensei Ravey Page 2"/>
            </Layout>);
    }
}

export default SenseiGrahamRavey;
